.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.general-card {
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
    display: flex;
    gap: 20px;
}

.general-card-section {
    flex: 1;
}

.general-card-section:first-child {
    max-width: 300px;
}

.vertical-line {
    width: 1.5px;
    background-color: #DFDFDF;
    margin: 0 20px;
}

.general-card-header h2 {
    margin: 0;
}

.general-card-header p {
    margin: 0;
    color: #666;
}

.general-card-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.field {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
}

.field .label {
    margin-bottom: 5px;
}

.field .value {
    display: flex;
    align-items: center;
    border-radius: 4px;
    line-height: 16px;
    color: #8E8E8E;
    word-break: break-word;
}

.field .value img {
    margin-right: 10px;
}

button {
    float: right;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: lightgrey;
    color: black;
    cursor: pointer;
}

button:hover {
    background-color: #DFDFDF;
}

@media (max-width:992px){
    .general-card {
        gap:0;
    }
}

@media(max-width:767px) {
    .general-card {
        padding: 20px;
        display: block;
        gap: 20px;
    }
    .general-card-section:first-child{margin-bottom: 30px;}
    
}