@import url('https://fonts.googleapis.com/css?family=Ubuntu');



.custom-tabs-panel {
  position: sticky;
  top: 65px;
  right: 0;
  z-index: 9;
}

.custom-tabs-panel input[type="checkbox"],input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  clip: rect(0, 0, 0, 0);
  visibility: hidden;
  opacity: 0;
  transform: matrix3d(0,0,0.00,0,0.00,0,0.00,0,0,0,1,0,0,0,0,1);
  z-index: -1;
}

.custom-tabs-panel .custom-tabs {
  display: flex;
}

.custom-tabs-panel .custom-tab {
  flex: 1;
  position: relative;
  margin-top: 1.5rem;
  text-align: center;
  color: #707a89;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s ease;
}

.custom-tabs-panel .custom-tab label {
  display: block;
  height: 100%;
  padding: 0 0.75rem;
  border: none;
  cursor: pointer;
  position: relative;
}

.custom-tabs-panel .custom-tab .custom-content .custom-icon {
  margin-bottom: 1rem;
  font-size: 1.375rem;
  text-align: center;
}

.custom-tabs-panel .custom-tab .custom-content .custom-text {
  margin-bottom: 1.563rem;
  font-family: 'Ubuntu', 'Myriad Pro', sans-serif;
  font-size: 12px;
}

.custom-tabs-panel .custom-tab label::after {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  display: block;
  width: 100%;
  height: 4.5px;
  opacity: 0;
  background-color: #00CC9C;
  transition: opacity 0.4s ease-in-out;
}

.custom-tabs-panel .custom-tab:hover {
  color: #00CC9C;
}

.custom-tabs-panel .custom-separator {
  width: 100%;
  height: 3px;
  background-color: #DFDFDF;
}

.custom-panels {
  border: none;
}

.custom-tabs-panel .custom-tab-panel .custom-content {
  font-family: 'Ubuntu', 'Myriad Pro', sans-serif;
  color: #707a89;
  animation: fade 0.3s linear;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
