@import url('https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.7.2/font/bootstrap-icons.min.css');

.notifications-container {
    background-color: white;
    color: black;
    padding: 20px !important;
    border-radius: 10px;
    font-family: Arial, sans-serif;
    margin-bottom: 30px;
}

.notification {
    display: flex;
    border-bottom: 1px solid #333;
    padding: 24px 16px 24px 16px;
    gap: 16px;
    align-items: center;
    flex-wrap: nowrap !important;
}

.notification:last-child {
    border-bottom: none;
}

.notification-icon {
    font-size: 1.5em;
    padding: 0 !important;
}

.content {
    flex-grow: 1;
    gap: 10px;
    align-items: center;
    width: 95% !important;
}

.content p {
    margin: 0;
}

.content a {
    text-decoration: underline;
}

.content a:hover {
    text-decoration: underline;
}

.time {
    font-size: 0.9em;
    color: #888;
}

.action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.notification-title-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.notification-action-container {
    width: 300px;
    align-items: center;
}