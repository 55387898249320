.investment-card {
    position: relative;
    color: white;
    padding: 24px !important;
    overflow: hidden;
    /* Ensure child elements don't overflow */
    background-color: rgba(0, 0, 0, 0.6);
    /* Fallback background color */
}

.investment-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: url('../../../assets/marketplaceDetalis.png') no-repeat center center; */
    background-size: cover;
    opacity: 0.2;
    /* Adjust opacity as needed */
    z-index: 1;
}

.investment-card .header,
.investment-card .content {
    position: relative;
    z-index: 2;
}

.tab-container {
    width: 45%;
}

.header {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.tab {
    font-size: 11px;
    flex: 1;
    text-align: center;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    margin: 0 5px;
}

.content {
    display: flex;
    justify-content: space-between;
}

.title {
    font-size: 1.625rem;
    font-weight: 500;
    line-height: 2rem;
}

.text-gray {
    color: #9E9E9E;
}

.subtitle {
    margin-top: 16px;
    margin-bottom: 16px;
}

.country {
    font-size: 1rem;
}

.invest-button {
    background-color: #00c853;
    border: none;
    padding: 10px 20px;
    margin: 10px 0
}

.info {
    display: flex;
    align-items: flex-start;
    gap: 30px
}

.info-item {
    gap: 5px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-direction: column;
}

.info-label {
    font-size: 12px;
    margin-right: 10px;
}

.info-value {
    font-size: 15px;
    font-weight: bold;
}