/* Master styles that can be used across the entire application */
.placeholder-sm::placeholder {
  font-size: 11px;
  /* equivalent to text-sm in Tailwind */
}

h1 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 3rem;
}

h2 {
  font-size: 1.625rem;
  font-weight: 500;
  line-height: 2.438rem;
}

h3 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.875rem;
}

h4 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
}

h5 {
  font-size: 0.938rem;
  font-weight: 500;
  line-height: 1.406;
}

h6 {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.313rem;
}

.body-medium {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.125rem;
}

.body-regular {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.125rem;
}

.body-small-bold {
  font-size: 0.688rem;
  font-weight: 600;
  line-height: 1.031rem;
}

.body-small-regular {
  font-size: 0.688rem;
  font-weight: 400;
  line-height: 1.031rem;
}

.caption-medium {
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 0.938rem;
}

.caption-regular {
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 0.938rem;
}

.color-primary {
    background-color: #00CC9C;
}

.color-secondary {
  background-color: #f2f2f2;
}

.text-secondary {
  color: #f2f2f2;
}

.width-40-percent {
  width: 40%;
}

.width-100-percent {
  width: 100%;
}

.margin-2rem {
  margin: 2rem;
}

.ml-1rem {
  margin-left: 1rem;
}

.mt-2rem {
  margin-top: 2rem;
}

.content-wrap {
  flex: 1;
  /* Allows content to expand */
  /* Remove the comment syntax and ensure this is active */
}

/* Center Content */
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}



.flex-column {
  flex-direction: column;
  /* Stack children vertically */
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* This class can be used to center content horizontally within its parent */
.horizontal-center {
  display: flex;
  justify-content: center;
}

/* This class can be used to center content vertically within its parent */
.vertical-center {
  display: flex;
  align-items: center;
}

.center-vertical-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* This class can be used to center content both vertically and horizontally within its parent */
.full-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* This class can be used to center text within its container */
.text-center {
  text-align: center;
}

/* This class can be used for buttons or other clickable elements to indicate action */
.btn-center {
  display: block;
  /* Change to block to allow margin auto to work */
  margin-left: auto;
  margin-right: auto;
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}

/* Containers */

.page-container {
  background-color: #ffffff;
  padding: 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.08) 5px 5px 30px;
  border-radius: 15px;
  margin-top: 1.3rem;
}

/* SettingsPage.css */
.page-container-settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
}

/* Common header for pages */
.page-header {
  color: #333;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
}


/* Container for each major section */
.section-container {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 1.5rem;
  margin-block: 1rem;
  margin-bottom: 1rem;
  box-shadow: rgba(0, 0, 0, 0.08) 5px 5px 30px;
  overflow: clip;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.section-container--max {
  min-height: 400px;
  max-height: 400px;
  height: 100%;
  overflow-y: auto;
  justify-content: flex-start;
}

.space-evenly {
  display: flex;
  justify-content: space-evenly;
  /* Distributes space evenly between and around items */
  align-items: center;
  /* Align items vertically in the center */
  padding: 1rem;
  /* Padding inside the container for some spacing from the edges */
}

/* Common header for sections */
.section-header {
  color: #333;
  margin-top: 0.3rem;
  margin-bottom: 1rem;
  text-align: left;
}

/* Styled list for sections */
.section-list {
  list-style: none;
  padding: 0;
}

/* Styled list item */
.section-list-item {
  background-color: #fff;
  border: 1px solid #e1e1e1;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.section-list-item:hover {
  background-color: #eef6ff;
}

.section-list-item:hover {
  background-color: #eef6ff;
}

.horizontal-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* Cards spaced evenly horizontally */
}

/* Style for each individual card */
.two-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* Cards spaced evenly horizontally */
  padding: 0rem;
  margin-block: 1rem;
  margin-bottom: 1rem;
  align-items: stretch;
}

.colored-box {
  display: flex;
  height: 5rem;
  width: 15rem;
  border-radius: 5px;
  /* Add rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.width-50 {
  width: 50rem;
}

.equal-height {
  display: flex;
  flex-wrap: wrap;
}

.equal-height>* {
  flex: 1;
  margin: 0 10px;
  /* Add margin between cards as needed */
}

/* Common button styling */
.btn {
  background-color: #0056b3;
  color: white;
  text-decoration: none;
  padding: 10px 15px;
  margin: 0 0px;
  border-radius: 5px;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #003d82;
}

.btn-danger {
  background-color: #d9534f;
  color: white;
}


/* Content wrapper */
.content-wrap {
  flex: 1;
  /* Allows content to expand and push footer down */
}

/* Penomo Button */
.btn-penomo {
    background-color: #00CC9C;
  /* Penomo color */
  color: #333;
  /* Text color set to black */
  border: none;
  /* Removes any default border */
  padding: 10px 15px;
  /* Padding for size */
  border-radius: 5px;
  /* Rounded corners */
  font-weight: 500;
  /* Bold font */
  cursor: pointer;
  /* Cursor indicates clickable button */
  outline: none;
  /* Removes outline on focus for some browsers */
  transition: all 0.3s;
  /* Smooth transition for hover effect */
  display: block;
}

.btn-penomo:hover {
  background-color: #00a47e;
  /* This will make the button green when clicked */
  color: white;
  /* Optional: change text color to white when active/selected */
  /* Any other styles you want to apply when the button is clicked */
}

.btn-penomo.btn-disabled {
  background-color: grey;
  /* Change the background color for the disabled state */
  cursor: default;
  /* Disable the pointer cursor */
}

.btn-penomo:active {
    background-color: #00CC9C;
  /* This will make the button green when clicked */
  color: white;
  /* Optional: change text color to white when active/selected */
  /* Any other styles you want to apply when the button is clicked */
}

.btn-penomo-secondary {
  background-color: #9bbab6;
  /* Penomo color */
  color: #333;
  /* Text color set to black */
  border: none;
  /* Removes any default border */
  padding: 10px 15px;
  /* Padding for size */
  border-radius: 5px;
  /* Rounded corners */
  font-weight: 500;
  /* Bold font */
  cursor: pointer;
  /* Cursor indicates clickable button */
  outline: none;
  /* Removes outline on focus for some browsers */
  transition: background-color 0.3s;
  /* Smooth transition for hover effect */
  display: block;
}

.btn-penomo-secondary:active {
    background-color: #00CC9C;
  /* This will make the button green when clicked */
  color: white;
  /* Optional: change text color to white when active/selected */
  /* Any other styles you want to apply when the button is clicked */
}

.btn-penomo-secondary:hover {
    background-color: #00CC9C;
  /* This will make the button green when clicked */
  color: white;
  /* Optional: change text color to white when active/selected */
  /* Any other styles you want to apply when the button is clicked */
}


.nav-button {
  min-width: 200px;
  margin-top: 1rem;
}

.toggle-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.toggle-button {
  background-color: #f0f0f0;
  /* Light grey background for the non-active button */
  color: #333;
  /* Dark text for contrast */
  border: 1px solid #ccc;
  /* Light grey border for non-active buttons */
  padding: 10px 15px;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  margin-right: 5px;
  /* Space between buttons */
}

.toggle-button:last-child {
  margin-right: 0;
  /* Remove margin for the last button */
}

.toggle-button.active,
.toggle-button:hover {
    background-color: #00CC9C;
  /* Penomo color for active/hover state */
  color: #333;
  /* Text color set to black for active/hover state */
    border: 1px solid #00CC9C;
  /* Penomo color border for active/hover state */
}

.info-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #00CC9C;
  /* Penomo green */
  color: #ffffff;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
}

/* Popup styles */
.popup-header {
  position: relative;
  /* This ensures that absolute positioning is relative to this container */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

/* Make sure the title does not overlap with the close button */
.popup-header h2 {
  padding-right: 40px;
  /* Adjust as needed */
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Ensure it's on top */
  padding: 1rem;
  /* Add padding to ensure margin from the window borders */
  box-sizing: border-box;
  /* Include padding in the width and height calculations */
}

.popup-content {
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  width: calc(100% - 2rem);
  /* Adjust width to account for padding */
  max-width: 500px;
  display: flex;
  /* Use flexbox to align children */
  flex-direction: column;
  /* Stack children vertically */
  justify-content: center;
  /* Center children vertically */
  align-items: center;
  /* Center children horizontally */
  box-sizing: border-box;
  /* Include padding in the width and height calculations */
}

/* Close button */
.close {
  position: absolute;
  /* Absolute position for the close button */
  top: 1px;
  /* Adjust as needed to move up towards the top corner */
  right: 1px;
  /* Adjust as needed to move towards the right corner */
  cursor: pointer;
  font-size: 1.5rem;
  /* Adjust the size as needed */
  line-height: 1;
  color: #333;
  /* Adjust the color as needed */
}

.close:hover {
  color: #333;
}

.form-label {
  flex-basis: 30%;
  text-align: left;
}

.error {
  word-wrap: break-word;
  /* Ensures that the text breaks onto the next line and does not overflow */
  font-size: 0.688rem;
  /* Adjust the font size as needed */
  color: red;
  /* Example color for error messages */
  margin-top: 0.5em;
  /* Adjust space above the error message if needed */
}


/* GENERAL ACCORDION STYLING */
.accordion {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.itooltip {
  @apply inline-flex;
  vertical-align: bottom;
  margin: 0 0.25rem;

  svg {
    fill: #999999;
  }
}

[data-tooltip]:hover::after {
  display: block;
  position: absolute;
  content: attr(data-tooltip);
  border: 1px solid black;
  background: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 0.25em;
  font-size: 12px;
  max-width: 300px;
}

.project-form input:read-only {
  background-color: rgba(239, 239, 239, 0.3);
  pointer-events: none;
}

/* GENERAL CARD STYLING */

.card {
  max-width: 700px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 3px 3px 5px;
  border-radius: 15px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


/* GENERAL FORM STYLING */

.form {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: 90%;
  max-width: 100%;
  /* Ensure the form does not exceed the width of its container */
  margin: auto;
  /* Center the form horizontally */
}

.form-header {
  text-align: center;
  margin: 2rem;
}

.form-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* Space between form groups */
  padding: 0 20px;
  /* Equal padding on both sides */
  justify-content: left;
  /* Center the form groups horizontally */
  align-items: left;
  /* Align the form groups vertically */

}

.form-body-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  /* Space between form groups */
  padding: 0 20px;
  /* Equal padding on both sides */
  justify-content: left;
  /* Center the form groups horizontally */
  align-items: left;
  /* Align the form groups vertically */

}

/* .form-group {
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    flex: 0 1 100%; /* Full width on small screens 
} */

.form-group {
  display: flex;
  flex-direction: column;
  /* Stack elements vertically */
  align-items: flex-start;
  /* Align items to the start of the flex container */
  width: 100%;
  /* Take the full width */
}


.form-label {
  display: block;
  /* Make label a block-level element */
  /* width: 100%; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-control {
  width: 100%;
  /* Ensure input takes full width */
  flex-grow: 1;
  /* Allow the input to grow to fill the space */
  /* margin-right: 2rem; */
}



.form-separate-group {
  border: 1px solid black;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 5px;
}

/* Medium screens (e.g., tablets) */
@media (min-width: 600px) {
  .form-group {
    flex: 0 1 calc(50% - 20px);
    /* Two columns, accounting for gap */

  }
}

/* Large screens (e.g., desktops) */
@media (min-width: 900px) {
  .form-group {
    flex: 0 1 calc(33.33% - 20px);
    /* Three columns, accounting for gap */
  }
}

/* Extra large screens */
@media (min-width: 1200px) {
  .form-group {
    flex: 0 1 calc(25% - 20px);
    /* Four columns, accounting for gap */
  }
}

.form-control {
  width: 100%;
  /* Make sure the input takes the full width of the form-group */
  padding: 0.5rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

.form-footer {
  width: 100%;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.modal-body {
  padding: 20px;
  /* Adjust the padding as needed */
}

.modal-body p {
  word-wrap: break-word;
  /* This ensures that long words will wrap and not overflow */
  margin-bottom: 1rem;
  /* Adds some space below each paragraph if you have multiple */
}

.modal-footer {
  width: 100%;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;

}

.modal-backdrop {
  position: fixed;
  /* or absolute */
  top: 0;
  left: 0;
  min-width: 100vw;
  width: 100% !important;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Example background */
}

.progress-bar {
  width: 100%;
  min-width: 130px;
  min-width: none;
  background-color: #9bbab6;
  position: relative;
  border-radius: 8px;
  padding: 1rem;
}

.filler {
    background-color: #00CC9C;
  height: 100%;
  /* display: flex;
    align-items: center;
    justify-content: center; */
  position: absolute;
  /* Use absolute positioning */
  left: 0;
  /* Align to the left */
  top: 0;
  /* Align to the top */
  bottom: 0;
  /* Align to the bottom */
}

.balance-display {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  /* Three columns with equal width */
  gap: 10px;
  /* Adjust the gap as needed */
  align-items: left;
  /* Center-align items vertically */
  align-items: center;
}

.percentage-text {
  color: #333;
  font-weight: 500;
  position: absolute;
  /* Use absolute positioning */
  left: 50%;
  /* Center horizontally */
  top: 50%;
  /* Center vertically */
  transform: translate(-50%, -50%);
  /* Center text precisely */
}

.cards {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
    border-left: 5px solid #00CC9C;
  margin: 1.5rem 0;
  background-color: rgb(250, 250, 250);
  box-shadow: rgba(0, 0, 0, 0.05) 3px 3px 5px;
  border-radius: 8px;
  padding: 0.8rem;

  &:hover {
    border-left: 5px solid #00a47e;
    background: #eafff7;
  }
}

.cards.reward-transaction {
    border-left: 5px solid #FFD700;
  /* Yellow color for reward transactions */
}

.cards:hover.reward-transaction {
    border-left: 5px solid #FFC107;
  /* Darker yellow on hover */
  background: #fff7e6;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

/* Styles for labels and inputs together */
label {
  padding-bottom: 5px;
}

/* Styles for input fields */
body input[type="text"],
body input[type="date"],
body input[type="email"],
body input[type="tel"],
body input[type="number"],
body textarea,
body select {
  display: block;
  font-size: 0.688rem;
  font-weight: 400;
  line-height: 1.031rem;
  border-bottom: 1px solid theme("colors.monochrome.10");
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
}

input[type="text"]:focus,
input[type="date"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus textarea:focus {
  border: 1px solid theme("colors.green.500");
  --tw-ring-color: none;
}

/* #navbar-default a.active {
    font-weight: 500;
    background-color: #00CC9C;
    color: #fff;
} */

body .accordion-button:not(.collapsed) {
  background-color: #00cc9ce8;
  box-shadow: 0 0 0 0.25rem rgb(57 209 165 / 60%);
  color: white;
}

body .accordion-button:focus {
  box-shadow: 0 0 0 0.25rem rgb(57 209 165 / 60%);
}

body .form__non-editable {

  input[type="text"],
  input[type="date"],
  input[type="email"],
  input[type="tel"],
  input[type="number"],
  input[type="file"],
  textarea,
  select,
  button.fab {
    background-color: #f8f9fa;
    border: none;
    pointer-events: none;
  }
}

body .form__non-editable {
  button.fab {
    display: none;
  }
}

.vh-center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.nav-icon {
  width: 24px;
  height: 24px;
}

/* Additional styles for responsiveness and aesthetics */
@media (max-width: 768px) {
  .popup-content {
    width: 90%;
    padding: 1rem;
  }

  .form-label {
    flex-basis: 40%;
  }

  .form-control {
    flex-basis: 60%;
  }

  .form-body {
    grid-template-columns: 1fr;
    /* Stack the columns on top of each other */
  }

  .project-docs tr {
    display: grid;
    grid-auto-columns: max-content;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }

  .project-docs td {
    align-self: center;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #333;
  border-top: 1px solid #e7e7e7;
  text-align: center;
  padding: 1rem 0;
  width: 100%;
  color: white;

  &-text {
    margin-bottom: 0;
  }
}

/* THIS is DONE only for 'Last Investor Purchasing Requests' card in project overview page  */
.project-status-a-approved,
.project-status-a-accepted {
  background-color: theme("colors.status.listedBg");
  border: 1px solid theme("colors.status.listedBorder");
  color: theme("colors.status.listed");
}

.project-status-a-approved div,
.project-status-a-accepted div {
  background-color: theme("colors.status.listed");
}

/* ////////////////////////// */
.project-status-approved,
.project-status-accepted {
  background-color: theme("colors.status.approvedBg");
  border: 1px solid theme("colors.status.approvedBorder");
  color: theme("colors.status.approved");
}

.project-status-completed {
  background-color: theme("colors.status.listedBg");
  border: 1px solid theme("colors.status.listedBorder");
  color: theme("colors.status.listed");
}

.project-status-approved div,
.project-status-accepted div {
  background-color: theme("colors.status.approved");
}

.project-status-notApproved,
.project-status-not.accepted {
  background-color: theme("colors.status.notApprovedBg");
  border: 1px solid theme("colors.status.notApprovedBorder");
  color: theme("colors.status.notApproved");
}

.project-status-notApproved div,
.project-status-not.accepted div {
  background-color: theme("colors.status.notApproved");
}

.project-status-pending {
  background-color: theme("colors.status.pendingBg");
  border: 1px solid theme("colors.status.pendingBorder");
  color: theme("colors.status.pending");
}

.project-status-pending div {
  background-color: theme("colors.status.pending");
}

.project-status-issued {
  background-color: theme("colors.status.issuedBg");
  border: 1px solid theme("colors.status.issuedBorder");
  color: theme("colors.status.issued");
}

.project-status-issued div {
  background-color: theme("colors.status.issued");
}

.project-status-notSubmitted {
  background-color: theme("colors.status.notSubmittedBg");
  border: 1px solid theme("colors.status.notSubmittedBorder");
  color: theme("colors.status.notSubmitted");
}

.project-status-notSubmitted div {
  background-color: theme("colors.status.notSubmitted");
}

.project-status-rejected {
  background-color: theme("colors.status.issuedBg");
  border: 1px solid theme("colors.status.issuedBorder");
  color: theme("colors.status.issued");
}

.project-status-rejected div {
  background-color: theme("colors.status.issued");
}

.project-status-listed {
  background-color: theme("colors.status.listedBg");
  border: 1px solid theme("colors.status.listedBorder");
  color: theme("colors.status.listed");
}

.project-status-listed div {
  background-color: theme("colors.status.listed");
}

.company-kyb-status-approved {
  background-color: theme("colors.status.approvedBg");
  border: 1px solid theme("colors.status.approvedBorder");
  color: theme("colors.status.approved");
}

.company-kyb-status-approved div {
  background-color: theme("colors.status.approved");
}

.company-kyb-status-not-submitted {
  background-color: theme("colors.status.pendingBg");
  border: 1px solid theme("colors.status.pendingBorder");
  color: theme("colors.status.pending");
}

.company-kyb-status-not-submitted div {
  background-color: theme("colors.status.pending");
}
.project-status-not-submitted {
  background-color: theme("colors.status.notSubmittedBg");
  border: 1px solid theme("colors.status.notSubmittedBorder");
  color: theme("colors.status.notSubmitted");
}
.project-status-not-submitted div {
  background-color: theme("colors.status.notSubmitted");
}
.custom-modal {
  max-width: 80%;
  /* Adjust the width as needed */
  margin: auto;
  /* Center horizontally */
  margin-top: 50px;
  /* Adjust the top margin to center vertically */
}

/* Main calendar container */
.calendar-container {
  background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  color: #000000;
  display: inline-block;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 16px;
}

/* Dropdowns for month and year */
.calendar-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.calendar-header select {
  background-color: #333;
  color: #fff;
  padding: 8px;
  border: 1px solid #444;
  border-radius: 4px;
  cursor: pointer;
}

/* Styling for calendar */
.react-calendar {
  background-color: #ffffff;
  border: none;
  color: #000000;
  border-radius: 8px;
  padding: 10px 0 0;
}

.react-calendar__navigation {
  background-color: #1a1c22;
  color: #fff;
  margin-bottom: 10px;
}

.react-calendar__tile {
  background-color: transparent;
  color: #000000;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  transition: background-color 0.3s ease;
}
.react-calendar__tile:focus-visible {
  outline: 0;
}
.react-calendar__tile--now {
  background-color: #00cc9c;
  color: #fff;
}

.react-calendar__tile--active {
  background-color: #00cc9c;
  color: #fff;
}

.react-calendar__tile--range {
  background-color: #d2f7ef;
  color: #000000;
  border-radius: 0;
}

.react-calendar__viewContainer,
.react-calendar__month-view,
.react-calendar__month-view >div,
.react-calendar__month-view >div >div{
  height: 100%;
}
.react-calendar__month-view__days{
  height: 80%;
}
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd ,
body .react-calendar__tile:hover{
  background-color: #00cc9c;
  color: #fff;
}

.react-calendar__month-view__weekdays {
  background-color: #ffffff;
  color: #000000;
  padding-bottom: 20px;
}

.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  text-transform: uppercase;
  font-size: 0.875rem;
}
.react-calendar__month-view__weekdays__weekday abbr:where([title]) {
  text-decoration: none;
  color: #000000;
}
.transactions-page .transactions-date input {
  border-radius: 8px;
  line-height: 20px;
  font-size: 14px;
  /* text-align: center; */
}
body .transactions-dropdown input {
  min-height: auto;
}
body .transactions-dropdown div {
  font-size: 14px;
  line-height: 20px;
}
body .transactions-dropdown:focus {
  border: 2px solid #ffffff;
}
.react-calendar__navigation__arrow,
.react-calendar__navigation__label {
  display: none;
}

body .transactions-dropdown {
  outline: 1px solid transparent !important;
}
body .transactions-dropdown:focus {
  outline-color: #ffffff !important;
}

.date-inputs {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
}

.date-inputs input {
  background-color: #fff;
  color: #333;
  border: none;
  padding: 8px;
  border-radius: 4px;
}

.date-inputs span {
  color: #fff;
}


.notification-card-wrapper {
    position: relative;
    width: 90%;
    z-index: 10;
    padding: 1rem 0;
}

.notification-card {
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    margin: 0 auto;
}

.ic-copy-button svg:hover {
  fill: currentColor !important; /* Ensures the color remains consistent */
}