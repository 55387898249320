@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body,
html {
  height: 100%;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 17px;
  font-style: normal;
  font-weight: 400;
  overflow-x: hidden;
  background-color: rgb(247, 245, 242);
  -webkit-font-smoothing: antialiased;
  -webkit-text-strokeWidth: 0.2px;
}
.ReactFlagsSelect-module_selectOptions__3LNBJ{
  bottom: 100% !important;
}
.ReactFlagsSelect-module_selectBtn__19wW7{
  /* border:; */
}
.custom-dashed-border {
  border-image: repeating-linear-gradient(90deg, black 0, black 10px, transparent 10px, transparent 20px) 3;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 321px) and (max-width: 767px) {
  .notification-card1 {
    /* Apply styles for the notification cards within the specified range */
  }
  
  .notification-card1:nth-child(2) {
    top: -115px; /* Adjust this value as needed */
    width: 95%;
  }
  
  .notification-card1:nth-child(3) {
    top: -230px; /* Adjust this value as needed */
    width: 93%;
  }
}



@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    /* -webkit-appearance: none;
    margin: 0; */
  }
}